import React from 'react'
import { Layout, Stack, Hero, Main, Sidebar, PreFooter } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Pagination from '@components/Pagination'
import Seo from '@widgets/Seo'
import * as styles from "./Posts.css"
import Categories from '@widgets/Categories'
import Social from '@widgets/Social'
import HeroComponent from '../components/Hero/HeroComponent'
import { useBlogCategories } from '@helpers-blog'
import EventCard from '../components/EventCard/EventCard'

const Posts = ({ data: { paginatedPosts = {} }, ...props }) => {
  const { pageContext: { services = {}, basePath } = {} } = props
  const categories = useBlogCategories()

  return (
    <Layout {...props}>
      <Seo title='Home' />
      <Hero sx={{ bg: `background` }}>
        <HeroComponent  />
      </Hero>
      <Hero>
      <div className='events' style={styles.events}>
        <EventCard name={'TechnoArtKPI 2022'} slug={'/'}/>
        <EventCard name={'TechnoArtKPI 2021'} slug={'/'}/>
        <EventCard name={'TechnoArtKPI 2020'} slug={'/'}/>
        <EventCard name={'TechnoArtKPI 2019'} slug={'/'}/>
      </div>
      </Hero>
      <Divider />
      <Stack>
        <Main>
          <CardList
            variant={['horizontal-md', 'horizontal']}
            title='Recently Published'
            nodes={paginatedPosts.nodes}
            columns={[1]}
          />
        </Main>
        <Sidebar>
          <Categories categories={categories} />
          <Divider />
          <Social/>
        </Sidebar>
      </Stack>
      <Divider />
      <PreFooter>
        <Pagination {...paginatedPosts.pageInfo} basePath={basePath} />
      </PreFooter>
    </Layout>
  )
}

export default Posts
