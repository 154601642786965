import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import { Button, Box, Heading, Text } from 'theme-ui'

const HeroComponent = props => {
  const styles = {
    wrapper: {
      marginTop: '1rem',
      marginBottom: '1rem',
      borderRadius: '1rem',
      alignItems: `left`,
      justifyContent: `center`,
      position: `relative`,
      zIndex: 2
    },
    wrapper_text: {
      marginLeft: '1rem',
      alignItems: `left`,
      justifyContent: `center`,
      position: `relative`,
      zIndex: 3
    },
    author: {
      display: `inline-block`,
      color: `alpha`
    },
    occupation: {
      mb: 2
    },
    specialty: {
      color: `text`,
      mb: 2
    }
  }
  const data = useStaticQuery(heroQuery2)
  const image = getImage(data.test)

  return (
    <div style={styles.wrapper}>
      <Box style={{ display: 'grid', borderRadius: '1rem' }}>
        <Img
          style={{
            gridArea: '1/1',
            borderRadius: '1rem',
            height: 400
          }}
          imgStyle={{ borderRadius: '1rem' }}
          image={image}
          loading='eager'
          layout='fullWidth'
          aspectRatio={3 / 1}
        />
        <div
          style={{
            gridArea: '1/1',
            position: 'relative',
            placeItems: 'center',
            display: 'grid'
          }}
        >
          <div style={styles.wrapper_text}>
            <Heading variant='h1'>
              <Text sx={styles.author}>INTERNATIONAL TECHNOARTKPI 2022</Text>
            </Heading>
            <Heading variant='h3' sx={styles.specialty}>
              FESTIVAL IN THE FORMAT OF SOCIAL USEFUL INITIATIVES COMPETITION
              AMONG YOUNG PEOPLE
            </Heading>
            <Heading variant='h3'>
              <Text sx={styles.author}>
                "RESTORATION. RECONSTRUCTION. RENAISSANCE."
              </Text>
            </Heading>
            <Box variant='buttons.group'>
              <Button as={Link} to='/technoart2022int'>
                Learn More
              </Button>
            </Box>
          </div>
        </div>
      </Box>
    </div>
  )
}

export default HeroComponent

const heroQuery2 = graphql`
  query HeroQuery2 {
    test: file(absolutePath: { regex: "./main.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG)
      }
    }
  }
`
