import React from 'react'
import * as styles from './EventCard.css'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import { Button, Box, Heading, Text } from 'theme-ui'

const EventCard = props => {
  const { name, slug } = props

  const data = useStaticQuery(eventQuery)
  const image = getImage(data.test)

  return (
    <div className='wrapper' style={styles.wrapper}>
      <Box style={{ display: 'grid', borderRadius: '1rem' }}>
        <Img
          style={{
            gridArea: '1/1',
            borderRadius: '1rem',
            height: 256
          }}
          imgStyle={{ borderRadius: '1rem' }}
          image={image}
          loading='eager'
          layout='fullWidth'
          aspectRatio={1 / 1}
        />
        <div
          style={{
            gridArea: '1/1',
            position: 'relative',
            placeItems: 'center',
            display: 'grid'
          }}
        >
          <div className='wrapper_text' style={styles.wrapper_text}>
            <Heading variant='h2'>
              <Text
                className='author'
                sx={{ display: 'inline-block', color: 'alpha' }}
              >
                {name}
              </Text>
            </Heading>
            <Box variant='buttons.group'>
              <Button as={Link} to={slug}>
                Coming soon
              </Button>
            </Box>
          </div>
        </div>
      </Box>
    </div>
  )
}

export default EventCard

const eventQuery = graphql`
  query eventQuery {
    test: file(absolutePath: { regex: "./main.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        gatsbyImageData(placeholder: TRACED_SVG)
      }
    }
  }
`
